<template>
  <v-card
    class="mx-auto"
    max-width="374"
    elevation="0"
  >
    <v-card-title>Информация о мастере</v-card-title>

    <v-card-text>Мастер Елена Новикова осуществляет профессиональную деятельность на основании и в соответствии с:
      <br>
      <a href="http://publication.pravo.gov.ru/Document/Text/0001201811270056?actual=True">
        Федеральным Законом от 27.11.2018 N 422-ФЗ
      </a>
      и
      <br>
      <a href="http://publication.pravo.gov.ru/Document/View/1100202005120016?actual=True">
        Законом Республики Коми от 08.05.2020 N 23-РЗ
      </a>.
      <br><br>
      <b>Адрес осуществления профессиональной деятельности:</b>
      <Address
       :official="true"
      />
    </v-card-text>

  </v-card>
</template>

<script>
  import Address from "@/components/Address";
  export default {
    name: "MasterInfo",
    components: {Address}
  }
</script>

<style scoped>

</style>
